import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Regionala bredbandskoordinatorer"
        topParagraph="Regionala bredbandskoordinatorer arbetar för bättre samordning i bredbandsfrågor – genom att stödja kommuner, kartlägga behov och främja samverkan."
        images="/media/1265/1368803-mosaic-flag-of-sweden.jpg"
        textbody={[
          <h3>Bredbandskoordinatorernas uppdrag</h3>,
          <h6>
            En bredbandskoordinator fungerar som kontaktpunkt för
            bredbandsfrågor i länet för både offentliga och privata aktörer. I
            bredbandskoordinatorernas uppdrag ingår bland annat att:
          </h6>,
          <ul>
            ,
            <li>
              Verka främjande för att öka tillgången till effektiva, säkra och
              robusta elektroniska kommunikationer (bredband) i länen
            </li>
            <li>
              Ingå i, och vid behov utveckla, regionala nätverk för
              kontinuerligt erfarenhetsutbyte, spridning av goda exempel och
              kommunikation för relevanta aktörer i frågor kopplade till
              bredband
            </li>
            <li>
              Hålla sig informerade samt sprida information inom länet om
              initiativ som är relevanta för uppdraget
            </li>
            <li>
              Främja länsöverskridande samarbeten kring bredbandsutbyggnad
            </li>
            <li>
              Upprätthålla en kontinuerlig kontakt med Post- och telestyrelsen
              (PTS), som utgör en stödfunktion för bredbandskoordinatorerna samt
              delta i det nätverk som PTS tillhandahåller
            </li>
            <li>
              Bistå PTS i redovisning av hur verksamheten som regional
              bredbandskoordinator bedrivits.
            </li>
            <li>
              Vid behov samverka med PTS i bredbandsrelaterade frågor och på
              begäran bistå PTS att sammanställa ett underlag, för att
              myndigheten ska kunna besluta om prioriterade byggnader inom de av
              PTS utpekade stödområdena i regionen. Sammanställningen ska utgå
              från befintliga analyser och tillvarata särskilda geografiska och
              andra specifika förutsättningar. Underlaget ska levereras inom den
              tidsram som PTS anger. Framtagandet av underlag ska ske i
              samverkan med relevanta aktörer på lokal och regional nivå och
              framför allt med länsstyrelser och kommuner.
            </li>
          </ul>,
          <p>
            Funktionen som regional bredbandskoordinator är placerad i den
            organisation som har det regionala utvecklingsansvaret för
            respektive län. Sedan januari 2019 ansvarar regionen för regional
            utveckling i samtliga län.
          </p>,
          <h3>Sekretariatet för regionala bredbandskoordinatorer</h3>,
          <p>
            Till sin hjälp har de regionala bredbandskoordinatorerna ett
            nationellt sekretariat som är placerat på Post- och telestyrelsen.
            Det nationella sekretariatet är en stödfunktion för de regionala
            bredbandskoordinatorerna, som arbetar för att underlätta samverkan,
            bidra till kompetensutveckling och tillvarata det nationella
            perspektivet på bredbandsutbyggnad.
          </p>,
          <p>
            Sekretariatet arrangerar regelbundet nätverksträffar för regionala
            bredbandskoordinatorer. Syftet med träffarna är att bidra till
            samverkan, erfarenhetsutbyte och kompetensutveckling. Vi bjuder
            in&nbsp;marknadsaktörer, myndigheter och andra organisationer för
            att de ska informera om sin verksamhet och skapa möjlighet till
            dialog och nätverkande med bredbandskoordinatorerna!
          </p>,
          <p>
            Sekretariatet bidrar till samverkan mellan nationella och regionala
            aktörer, och uppföljning av de regionala bredbandskoordinatorernas
            verksamhet.&nbsp;Uppföljning av verksamheten görs årligen. Här kan
            du läsa uppföljningen för 2020:
            <a
              data-udi="umb://media/cd17de731626403fb9dcca4a95deba2f"
              href="/media/1243/2018-uppfoeljning-av-de-regionala-bredbandskoordinatorernas-verksamhet-2018-dnr-19-2103.pdf"
              title="2018 - Uppföljning av de regionala bredbandskoordinatorernas verksamhet 2018 - dnr 19-2103.pdf"
            ></a>
          </p>,
          <ul>
            ,
            <li>
              <a
                data-udi="umb://media/7762ea510d5f448ab6c34815638c0ce2"
                href="/media/1312/uppfoeljning-av-de-regionala-bredbandskoordinatorernas-verksamhet-2020.pdf"
                title="Uppföljning av de regionala bredbandskoordinatorernas verksamhet 2020.pdf"
              >
                Uppföljning av bredbandskoordinatorernas verksamhet år 2020
              </a>
            </li>
          </ul>,
        ]}
        cardtitle="Kontakta din regionala bredbandskoordinator"
        carddata={[
          {
            headline: <h6>Blekinge:</h6>,
            textbody: (
              <p>
                Rikard Svensson
                <br />
                <a href="mailto:rikard.svensson@regionblekinge.se">
                  rikard.svensson@regionblekinge.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Dalarna:</h6>,
            textbody: (
              <p>
                Anders Oksvold
                <br />
                <a href="mailto:anders.oksvold@regiondalarna.se">
                  anders.oksvold@regiondalarna.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Gotland:</h6>,
            textbody: (
              <p>
                Anders Granvald
                <br />
                <a href="mailto:anders.granvald@gotland.se">
                  anders.granvald@gotland.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Gävleborg:</h6>,
            textbody: (
              <p>
                Annica Aneklev
                <br />
                <a href="mailto:annica.aneklev@regiongavleborg.se">
                  annica.aneklev@regiongavleborg.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Halland:</h6>,
            textbody: (
              <p>
                Ellinore Svan
                <br />
                <a href="mailto:ellinore.svan@regionhalland.se">
                  ellinore.svan@regionhalland.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Jämtland:</h6>,
            textbody: (
              <p>
                Peter Adolfsson
                <br />
                <a href="mailto:peter.adolfsson@regionjh.se">
                  peter.adolfsson@regionjh.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Jönköping:</h6>,
            textbody: (
              <p>
                Åslög Kantelius
                <br />
                <a href="mailto:aslog.kantelius@rjl.se">
                  aslog.kantelius@rjl.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Kalmar:</h6>,
            textbody: (
              <p>
                Magnus Hammarstedt
                <br />
                <a href="mailto:magnus.hammarstedt@regionkalmar.se">
                  magnus.hammarstedt@regionkalmar.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Kronoberg:</h6>,
            textbody: (
              <p>
                Peter Kirsten
                <br />
                <a href="mailto:peter.kirsten@kronoberg.se">
                  peter.kirsten@kronoberg.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Norrbotten:</h6>,
            textbody: (
              <p>
                Roger Ylinenpää
                <br />
                <a href="mailto:roger.ylinenpaa@norrbotten.se">
                  roger.ylinenpaa@norrbotten.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Skåne:</h6>,
            textbody: (
              <p>
                Lars Winther-Hansen
                <br />
                <a href="mailto:lars.winther-hansen@skane.se">
                  lars.winther-hansen@skane.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Stockholm:</h6>,
            textbody: (
              <p>
                Peter Karnung
                <br />
                <a href="mailto:peter.karnung@sll.se">peter.karnung@sll.se</a>
              </p>
            ),
          },
          {
            headline: <h6>Sörmland:</h6>,
            textbody: (
              <p>
                Göran Wide
                <br />
                <a href="mailto:goran.wide@region.sormland.se">
                  goran.wide@region.sormland.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Uppsala:</h6>,
            textbody: (
              <p>
                Sofia Millberg
                <br />
                <a href="mailto:sofia.millbert@regionuppsala.se">
                  sofia.millbert@regionuppsala.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Värmland:</h6>,
            textbody: (
              <p>
                Erik Larsson
                <br />
                <a href="mailto:erik.larsson@regionvarmland.se">
                  erik.larsson@regionvarmland.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Västerbotten:</h6>,
            textbody: (
              <p>
                Eva-Marie Marklund
                <br />
                <a href="mailto:eva-marie.marklund@regionvasterbotten.se">
                  eva-marie.marklund@regionvasterbotten.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Västernorrland:</h6>,
            textbody: (
              <p>
                Fredrik Bergman
                <br />
                <a href="mailto:fredrik.bergman@rvn.se">
                  fredrik.bergman@rvn.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Västmanland:</h6>,
            textbody: (
              <p>
                Lars Eriksson
                <br />
                <a href="mailto:lars.o.eriksson@regionvastmanland.se">
                  lars.o.eriksson@regionvastmanland.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Västra Götaland:</h6>,
            textbody: (
              <p>
                Eric Åkerlund
                <br />
                <a href="mailto:Eric.akerlund@vgregion.se">
                  Eric.akerlund@vgregion.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Örebro:</h6>,
            textbody: (
              <p>
                Linus Grabö
                <br />
                <a href="mailto:linus-goran.grabo@regionorebrolan.se">
                  linus-goran.grabo@regionorebrolan.se
                </a>
              </p>
            ),
          },
          {
            headline: <h6>Östergötland:</h6>,
            textbody: (
              <p>
                Jonas Jernberg
                <br />
                <a href="mailto:Jonas.jernberg@regionostergotland.se">
                  Jonas.jernberg@regionostergotland.se
                </a>
                <br />
                Magnus Hansson
                <br />
                <a href="mailto:magnus.hansson@regionostergotland.se">
                  magnus.hansson@regionostergotland.se
                </a>
              </p>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;
